@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.ContactGenAi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 40px auto 100px;
  flex-direction: column;

  @include breakpoint-for(desktop-small) {
    flex-direction: row;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto 50px;

    @include breakpoint-for(desktop-small) {
      margin: 0 auto;
    }

    h1 {
      font-family: $madeoutersans_medium;
      font-size: pxToRem(28px);
      letter-spacing: 0;
      color: black;
      margin: 0;
      text-align: left;

      @include breakpoint-for(desktop-small) {
        font-size: pxToRem(48px);
      }

      span {
        background-color: #e40030;
        color: white;
        font-family: $madeoutersans_medium;
        letter-spacing: 0;
      }
    }

    p {
      font-family: $madeoutersans_regular;
      font-size: pxToRem(15px);
      letter-spacing: 0;
      color: black;
      margin: 50px 0 0 0;
      text-align: left;
      max-width: 668px;
      width: fit-content;

      @include breakpoint-for(desktop-small) {
        margin: 75px 0 0 0;

        font-size: pxToRem(18px);
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    @include breakpoint-for(desktop-small) {
      max-width: 616px;
      max-height: 619px;
    }

    .container-contact {
      align-items: center;
      background-color: #f7f7f7;
      box-sizing: border-box;
      display: flex;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
      height: 100%;

      @include breakpoint-for(desktop-small) {
        max-width: 616px;
        padding: 95px 70px 73px;
        min-height: 352px;
      }

      .form-container {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 20px;
      }

      .custom-form {
        display: flex;
        flex-direction: column;
        gap: 23px;
        min-height: 132px;
        width: 100%;

        .form-row {
          display: flex;
          flex-direction: column;
          gap: 17px;
          height: 100%;
          justify-content: space-between;
          width: 100%;

          .form-group {
            display: flex;
            flex-direction: column;
            flex: 1;
            min-width: 45%;
            position: relative;

            &.error {
              input,
              textarea {
                border-color: #e40030;
              }
            }

            input {
              border: 1px solid #000000;
              border-radius: 0;
              box-sizing: border-box;
              font-family: $madeoutersans_light;
              font-size: 15px;
              height: 58px;
              outline: none;
              padding: 10px;
              transition: border-color 0.3s;
              width: 100%;

              &::placeholder {
                color: #848484;
              }

              &:focus {
                border-color: #000;
              }
            }

            .error {
              bottom: -14px;
              color: red;
              font-size: 12px;
              position: absolute;
              text-align: left;
            }
          }

          textarea {
            border: 1px solid #000000;
            box-sizing: border-box;
            font-family: $madeoutersans_light;
            font-size: 14px;
            height: 132px;
            outline: none;
            padding: 10px;
            resize: none;
            transition: border-color 0.3s;
            width: 100%;

            &::placeholder {
              color: #848484;
            }

            &:focus {
              border-color: #000;
            }
          }

          .textarea-group {
            flex: 2;
            height: 100%;
            max-height: 132px;
          }

          .btn-enviar {
            align-self: flex-end;
            border: none;
            background-color: #000;
            color: #fff;
            cursor: pointer;
            width: 100%;
            font-family: $madeoutersans_bold;
            padding: 17px 66px;
            font-size: pxToRem(15px);
            transition: background-color 0.3s;

            @include breakpoint-for(desktop-small) {
              font-size: pxToRem(20px);
            }

            &:hover {
              background-color: #333;
            }
          }
        }

        .text-container {
          p {
            font-family: $madeoutersans_regular;
            font-size: pxToRem(17px);
            letter-spacing: 0;
            color: #000;
            margin: 0;
            text-align: left;

            @include breakpoint-for(desktop-small) {
              font-size: pxToRem(28px);
            }

            span {
              background-color: #e40030;
              color: white;
              font-family: $madeoutersans_medium;
            }
          }
        }
      }
    }
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  font-family: $madeoutersans_regular;
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.popup button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #e40030;
  color: white;
  border: none;
  font-family: $madeoutersans_bold;
  border-radius: 5px;
  width: 60%;
  font-size: 19px;
  cursor: pointer;
}

.popup button:hover {
  background-color: #e4003192;
}
