@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.ContactHome {
  align-items: center;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 90px 20px;
  overflow-x: hidden;

  @include breakpoint-for(desktop-small) {
    padding: 150px 100px 100px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 150px 190px 100px;
  }

  &.with-text {
    margin: 0;
  }

  &.with-witdh {
    margin: 0 0 50px 0;
  }

  .contact-home-container {
    align-items: flex-start;
    background-color: #f7f7f7;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    position: relative;
    width: 100%;

    .header-contact {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 100%;
      z-index: 10;

      img {
        position: absolute;
        right: 0;
        top: 140px;
        width: 150px;

        @include breakpoint-for(tablet) {
          position: initial;
          width: 300px;
        }
      }

      div {
        text-align: left;
        margin-bottom: 150px;

        @include breakpoint-for(tablet) {
          margin-bottom: 0;
        }

        h2 {
          font-family: $madeoutersans_light;
          font-weight: 300;
          font-size: pxToRem(25px);
          margin: 0;

          @include breakpoint-for(tablet) {
            font-size: pxToRem(35px);
          }

          strong {
            font-family: $madeoutersans_bold;
          }
        }

        h3 {
          font-family: $madeoutersans_light;
          font-size: pxToRem(20px);
          font-weight: 300;
          margin: 0;

          @include breakpoint-for(tablet) {
            font-size: pxToRem(35px);
          }
        }

        .description {
          font-family: $madeoutersans_regular;
          font-size: pxToRem(20px);
          margin: 25px 0 0 0;
        }
      }
    }

    .container-contact {
      align-items: center;
      background-color: white;
      box-sizing: border-box;
      display: flex;
      padding: 40px;
      position: relative;
      top: -30px;
      width: 100%;

      @include breakpoint-for(tablet) {
        min-height: 352px;
      }

      .form-container {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 20px;
      }

      .custom-form {
        display: flex;
        flex-direction: column;
        gap: 17px;
        min-height: 132px;
        width: 100%;

        @include breakpoint-for(tablet) {
          flex-direction: row;
        }

        .form-row {
          display: flex;
          flex-direction: column;
          gap: 17px;
          height: 100%;
          justify-content: space-between;
          width: 100%;

          .form-group {
            display: flex;
            flex-direction: column;
            flex: 1;
            min-width: 45%;
            position: relative;

            &.error {
              input,
              textarea {
                border-color: #e40030;
              }
            }

            input {
              border: 1px solid #000000;
              box-sizing: border-box;
              font-family: $madeoutersans_light;
              font-size: 14px;
              height: 58px;
              outline: none;
              padding: 10px;
              transition: border-color 0.3s;
              width: 100%;

              &::placeholder {
                color: #848484;
              }

              &:focus {
                border-color: #000;
              }
            }

            .error {
              bottom: -14px;
              color: red;
              font-size: 12px;
              position: absolute;
              text-align: left;
            }
          }

          textarea {
            border: 1px solid #000000;
            box-sizing: border-box;
            font-family: $madeoutersans_light;
            font-size: 14px;
            height: 132px;
            outline: none;
            padding: 10px;
            resize: none;
            transition: border-color 0.3s;
            width: 100%;

            &::placeholder {
              color: #848484;
            }

            &:focus {
              border-color: #000;
            }
          }

          .textarea-group {
            flex: 2;
            height: 100%;
            max-height: 132px;
          }

          .btn-enviar {
            align-self: flex-end;
            border: none;
            background-color: #000;
            color: #fff;
            cursor: pointer;
            flex: 1;
            font-family: $madeoutersans_bold;
            padding: 17px 66px;
            transition: background-color 0.3s;

            &:hover {
              background-color: #333;
            }
          }
        }
      }
    }
  }

  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    font-family: $madeoutersans_regular;
  }

  .popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .popup button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #e40030;
    color: white;
    border: none;
    font-family: $madeoutersans_bold;
    border-radius: 5px;
    width: 60%;
    font-size: 19px;
    cursor: pointer;
  }

  .popup button:hover {
    background-color: #e4003192;
  }
}
